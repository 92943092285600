<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="isEnrolledCounseling">
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          <router-link :to="{ name: 'counseling' }">
            Counseling
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="title">
      <h1>
        {{ pageTitle }}
        <span class="action-icon" @click="openSetUsingApplicationDialog">
          <i class="fas fa-plus" />
        </span>
      </h1>
    </div>
    <hr class="separate-line" />
    <el-table :data="thirdServiceWithColleges">
      <el-table-column width="150" :label="$t('application.columns.appName')">
        <template slot-scope="scope">
          <router-link :to="getThirdAppDetailRouterObject(scope.row.thirdApp)">
            {{ thirdAppTypeDict[scope.row.thirdApp] }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="200" :label="$t('application.details.loginInfo')">
        <template slot-scope="scope">
          <template v-if="scope.row.account">
            <div>{{ scope.row.account }}</div>
            <div>{{ handleMaskPassword(scope.row.thirdApp, scope.row.password) }}</div>
          </template>
          <template v-else>
            <router-link
              class="warning-text"
              :to="
                getThirdAppDetailRouterObject(scope.row.thirdApp, {
                  isEditLoginInfo: 1
                })
              "
            >
              {{ $t("application.details.missingLoginInfo") }}
            </router-link>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <div v-if="scope.row.password">
            <el-tooltip
              :content="
                maskPasswordsMap[scope.row.thirdApp]
                  ? $t('application.showPassword')
                  : $t('application.hidePassword')
              "
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                class="action-icon"
                @click="
                  () => {
                    maskPasswordsMap = {
                      ...maskPasswordsMap,
                      [scope.row.thirdApp]: !maskPasswordsMap[
                        scope.row.thirdApp
                      ]
                    };
                  }
                "
              >
                <span v-if="maskPasswordsMap[scope.row.thirdApp]" key="eye">
                  <i class="fas fa-eye" />
                </span>
                <span v-else key="slash">
                  <i class="fas fa-eye-slash" />
                </span>
              </span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('application.columns.collegeList')">
        <template slot-scope="scope">
          <div
            :key="school.id"
            v-for="school in scope.row.schools"
            class="schoolList"
          >
            <div class="school">
              <el-tooltip
                v-if="school.applicationPortal === null"
                placement="top"
                popper-class="tooltipColor"
              >
                <div slot="content">
                  {{ $t("application.applicationNotChosen") }}
                  <a target="_blank" :href="getEditCollegeLink(school.collectId)">
                    <strong class="editCollegeLink">
                      {{ $t("application.clickMeToSetApplication") }}
                    </strong>
                  </a>
                </div>
                <span>
                  <i
                    key="sameProtal"
                    class="fas fa-question-circle action-icon"
                  />
                </span>
              </el-tooltip>
              <el-tooltip
                v-else-if="scope.row.thirdApp !== school.applicationPortal"
                :content="`${$t('application.thisSchoolUses')}${thirdAppTypeDict[school.applicationPortal]}${$t('application.applicationUsed')}`"
                placement="top"
                popper-class="tooltipColor"
              >
                <span><i key="sameProtal" class="fas fa-times-circle action-icon" /></span>
              </el-tooltip>
              <el-tooltip
                v-else-if="scope.row.thirdApp === school.applicationPortal"
                :content="$t('application.applicationChosen')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span><i key="sameProtal" class="fas fa-check-circle action-icon" /></span>
              </el-tooltip>
              <CollectCollegeTypeTag
                v-if="school.collectType !== 'DEFAULT'"
                size="mini"
                :type="school.collectType"
              />
              <router-link
                :class="
                  collegeUsingOtherThirdApp(
                    scope.row.thirdApp,
                    school.applicationPortal,
                    school
                  )
                    ? 'collegeUsingOtherThirdApp'
                    : ''
                "
                :to="{
                  name: 'School',
                  params: { schoolId: school.slug, type: 'overview' }
                }"
              >
                <span v-if="lang === 'EN-US'">{{
                  school.name[langList[lang]]
                }}</span>
                <span v-else
                  >{{ school.name[langList[lang]] }}&nbsp;({{ school.name["en"] }})</span
                >
              </router-link>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="120" :label="$t('application.columns.actions')">
        <template slot-scope="scope">
          <router-link :to="getThirdAppDetailRouterObject(scope.row.thirdApp)">
            <i class="fas fa-eye action-icon" />
          </router-link>
          <router-link
            :to="
              getThirdAppDetailRouterObject(scope.row.thirdApp, {
                isEditLoginInfo: 1
              })
            "
          >
            <i class="fas fa-edit action-icon" />
          </router-link>
          <span
            class="warning-icon"
            @click="removeUsingApplication(scope.row.thirdApp)"
          >
            <i class="fas fa-trash-alt" />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="$t('application.chooseApp')"
      :visible="setUsingApplicationDialogVisible"
      :show-close="false"
      width="600px"
    >
      <el-checkbox-group
        v-model="newUsingApplications"
        @change="value => (newUsingApplications = value)"
      >
        <div :key="thirdApp" v-for="thirdApp in Object.keys(thirdAppTypeDict)">
          <el-tooltip
            v-if="applicationHasColleges(thirdApp)"
            :content="$t('application.removeSchoolBeforeRemovingApp')"
            placement="top"
            popper-class="tooltipColor"
          >
            <el-checkbox disabled :label="thirdApp">
              {{ thirdAppTypeDict[thirdApp] }}
            </el-checkbox>
          </el-tooltip>
          <el-checkbox v-else :label="thirdApp">
            {{ thirdAppTypeDict[thirdApp] }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSetUsingApplicationDialog">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button type="primary" @click="updateUsingApplication">
          {{ $t("button.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import CollectCollegeTypeTag from "@/components/school/CollectCollegeTypeTag";
import privateApi from "@/apis/private";
import collectSchoolApi from "@/apis/collectSchool";
import profileApi from "@/apis/profile";
import { thirdAppTypeDict } from "@/class/ThirdApp";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    CollectCollegeTypeTag
  },
  computed: {
    pageTitle() {
      if (this.student.first_name) {
        return `${this.student.first_name}'s Application Forms`;
      } else {
        return "";
      }
    },
    thirdAppTypeDict() {
      return thirdAppTypeDict;
    },
    thirdApp() {
      return this.$store.getters["thirdApp/thirdApp"];
    },
    usingApplications() {
      return this.$store.getters["thirdApp/usingApplications"];
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },

    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    studentUserId() {
      return this.student.id;
    },
    isEnrolledCounseling() {
      return this.$route.name === "counselingThirdApp";
    }
  },
  data() {
    return {
      setUsingApplicationDialogVisible: false,
      newUsingApplications: [],
      canNotSignInThirdDialogVisible: false,
      maskPasswordsMap: {},
      student: {},
      thirdServiceWithColleges: [],
      sessionClass: {
        course_session: {}
      }
    };
  },
  async created() {
    if (this.isEnrolledCounseling) {
      const customLesson = await this.fetchCustomLesson();
      this.student = customLesson.student_class_tickets[0].student;
    } else {
      const student = await this.fetchUserProfile();
      this.student = student;
    }
    await this.loadApplicationsList();
    this.initNewUsingApplications();
  },
  methods: {
    getThirdAppDetailRouterObject(thirdAppType, query) {
      if (this.isEnrolledCounseling) {
        return {
          name: "counselingThirdAppDetail",
          query,
          params: { id: this.$route.params.id, thirdAppType }
        };
      }

      return {
        name: "counselingUserThirdAppDetail",
        query,
        params: { username: this.$route.params.username, thirdAppType }
      };
    },
    getEditCollegeLink(collectedCollegeId) {
      if (this.isEnrolledCounseling) {
        return `/counseling/${this.$route.params.id}/collected/colleges/${collectedCollegeId}/edit`;
      } else {
        return `counseling/student/${this.$route.params.username}/collected/colleges/${collectedCollegeId}/edit`;
      }
    },
    collegeUsingOtherThirdApp(targetThirdApp, usingThirdApp, school) {
      if (usingThirdApp !== null) {
        return targetThirdApp !== usingThirdApp;
      } else {
        return false;
      }
    },
    initNewUsingApplications() {
      this.newUsingApplications = [];
      this.thirdServiceWithColleges.map(thirdService => {
        this.newUsingApplications = [
          ...this.newUsingApplications,
          thirdService.thirdApp
        ];
      });
    },
    async loadApplicationsList() {
      await this.$store.dispatch("thirdApp/getUsingApplications", this.studentUserId);
      await this.$store.dispatch("thirdApp/getThirdAccounts", this.studentUserId);
      await this.setThirdServiceWithColleges();
    },
    async openSetUsingApplicationDialog() {
      if (this.newUsingApplications.length !== 0) {
        await this.$store.dispatch("thirdApp/updateUsingApplications", {
          studentUserId: this.studentUserId,
          usingApplications: this.newUsingApplications
        });
      }
      this.setUsingApplicationDialogVisible = true;
    },
    closeSetUsingApplicationDialog() {
      this.setUsingApplicationDialogVisible = false;
    },
    async updateUsingApplication() {
      await this.$store.dispatch("thirdApp/updateUsingApplications", {
        studentUserId: this.studentUserId,
        usingApplications: this.newUsingApplications
      });
      await this.$store.dispatch("thirdApp/getUsingApplications", this.studentUserId);
      this.loadApplicationsList();
      this.closeSetUsingApplicationDialog();
    },
    async removeUsingApplication(removeApplication) {
      if (this.applicationHasColleges(removeApplication)) {
        this.$message.error(this.$t("application.removeSchoolBeforeRemovingApp"));
      } else {
        try {
          await this.$confirm(
            this.$t("message.areYouSure"),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        } catch (e) {
          return false;
        }
        const removedUsingApplications = this.newUsingApplications
          .filter(usingApplication => !(usingApplication === removeApplication));

        await this.$store.dispatch("thirdApp/updateUsingApplications", {
          studentUserId: this.studentUserId,
          usingApplications: removedUsingApplications
        });
        this.loadApplicationsList();
      }
    },
    applicationHasColleges(thirdApp) {
      const application = this.thirdServiceWithColleges.find(
        thirdService => thirdService.thirdApp === thirdApp
      );
      if (application) {
        return 1 <= application.schools.length;
      } else {
        return false;
      }
    },
    handleMaskPassword(thirdApp, password) {
      if (this.maskPasswordsMap[thirdApp]) {
        const passwordLength = password?.length || 0;
        return "".padStart(passwordLength, "●");
      }
      return password;
    },
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.student = customLesson.student_class_tickets[0].student;
      this.sessionClass = {
        ...customLesson.session_class
      };
      return customLesson;
    },
    async fetchUserProfile() {
      const { basic_info, student: { counseling_schools_type } } = await profileApi.getUserByName(
        this.$route.params.username
      );
      this.student = { ...basic_info, counseling_schools_type };
      return this.student;
    },
    async setThirdServiceWithColleges() {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: this.studentUserId
      });

      const existThirdAppSet = new Set();
      const schoolThirdAppMap = {};

      counseling_schools.forEach((counselingSchool) => {
        counselingSchool.school.third_service_schools.forEach(({ service }) => {
          existThirdAppSet.add(service);
          if (schoolThirdAppMap[service] === undefined) {
            schoolThirdAppMap[service] = [];
          }
          schoolThirdAppMap[service].push({
            ...counselingSchool.school,
            collectId: counselingSchool.id,
            collectType: counselingSchool.type,
            applicationPortal: counselingSchool.application_portal
          });
        });
      });
      this.usingApplications.forEach((usingApplication) => {
        existThirdAppSet.add(usingApplication);
      });
      this.thirdServiceWithColleges = [];
      const thirdAppAccountsMap = this.$store.getters["thirdApp/thirdAppAccountsMap"];
      existThirdAppSet.forEach((thirdApp) => {
        this.thirdServiceWithColleges.push({
          thirdApp,
          ...thirdAppAccountsMap[thirdApp],
          schools: (schoolThirdAppMap[thirdApp] || [])
        });
      });

      existThirdAppSet.forEach((thirdApp) => {
        this.maskPasswordsMap[thirdApp] = true;
      });
    }
  }
};
</script>

<style scoped>
.prompt {
  color: #999
}

.collegeUsingOtherThirdApp {
  opacity: 0.5;
  text-decoration: line-through;
}

.editCollegeLink {
  color: #fff;
}

.schoolList:not(:first-child) {
  border-top: 1px solid #EBEEF5;
  padding: 4px 0px;
}

.school {
  display: flex;
}
</style>
