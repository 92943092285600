var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[(_vm.isEnrolledCounseling)?_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'counselingClassesDetail',
            params: { id: _vm.$route.params.id }
          }}},[_c('IsOnlineIcon',{attrs:{"isOnline":_vm.sessionClass.course_session.is_online}}),_vm._v(" "+_vm._s(_vm.sessionClass.title)+" ")],1)],1):_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'counseling' }}},[_vm._v(" Counseling ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('div',{staticClass:"title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('span',{staticClass:"action-icon",on:{"click":_vm.openSetUsingApplicationDialog}},[_c('i',{staticClass:"fas fa-plus"})])])]),_c('hr',{staticClass:"separate-line"}),_c('el-table',{attrs:{"data":_vm.thirdServiceWithColleges}},[_c('el-table-column',{attrs:{"width":"150","label":_vm.$t('application.columns.appName')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":_vm.getThirdAppDetailRouterObject(scope.row.thirdApp)}},[_vm._v(" "+_vm._s(_vm.thirdAppTypeDict[scope.row.thirdApp])+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"200","label":_vm.$t('application.details.loginInfo')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.account)?[_c('div',[_vm._v(_vm._s(scope.row.account))]),_c('div',[_vm._v(_vm._s(_vm.handleMaskPassword(scope.row.thirdApp, scope.row.password)))])]:[_c('router-link',{staticClass:"warning-text",attrs:{"to":_vm.getThirdAppDetailRouterObject(scope.row.thirdApp, {
                isEditLoginInfo: 1
              })}},[_vm._v(" "+_vm._s(_vm.$t("application.details.missingLoginInfo"))+" ")])]]}}])}),_c('el-table-column',{attrs:{"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.password)?_c('div',[_c('el-tooltip',{attrs:{"content":_vm.maskPasswordsMap[scope.row.thirdApp]
                ? _vm.$t('application.showPassword')
                : _vm.$t('application.hidePassword'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon",on:{"click":() => {
                  _vm.maskPasswordsMap = {
                    ..._vm.maskPasswordsMap,
                    [scope.row.thirdApp]: !_vm.maskPasswordsMap[
                      scope.row.thirdApp
                    ]
                  };
                }}},[(_vm.maskPasswordsMap[scope.row.thirdApp])?_c('span',{key:"eye"},[_c('i',{staticClass:"fas fa-eye"})]):_c('span',{key:"slash"},[_c('i',{staticClass:"fas fa-eye-slash"})])])])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('application.columns.collegeList')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.schools),function(school){return _c('div',{key:school.id,staticClass:"schoolList"},[_c('div',{staticClass:"school"},[(school.applicationPortal === null)?_c('el-tooltip',{attrs:{"placement":"top","popper-class":"tooltipColor"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t("application.applicationNotChosen"))+" "),_c('a',{attrs:{"target":"_blank","href":_vm.getEditCollegeLink(school.collectId)}},[_c('strong',{staticClass:"editCollegeLink"},[_vm._v(" "+_vm._s(_vm.$t("application.clickMeToSetApplication"))+" ")])])]),_c('span',[_c('i',{key:"sameProtal",staticClass:"fas fa-question-circle action-icon"})])]):(scope.row.thirdApp !== school.applicationPortal)?_c('el-tooltip',{attrs:{"content":`${_vm.$t('application.thisSchoolUses')}${_vm.thirdAppTypeDict[school.applicationPortal]}${_vm.$t('application.applicationUsed')}`,"placement":"top","popper-class":"tooltipColor"}},[_c('span',[_c('i',{key:"sameProtal",staticClass:"fas fa-times-circle action-icon"})])]):(scope.row.thirdApp === school.applicationPortal)?_c('el-tooltip',{attrs:{"content":_vm.$t('application.applicationChosen'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',[_c('i',{key:"sameProtal",staticClass:"fas fa-check-circle action-icon"})])]):_vm._e(),(school.collectType !== 'DEFAULT')?_c('CollectCollegeTypeTag',{attrs:{"size":"mini","type":school.collectType}}):_vm._e(),_c('router-link',{class:_vm.collegeUsingOtherThirdApp(
                  scope.row.thirdApp,
                  school.applicationPortal,
                  school
                )
                  ? 'collegeUsingOtherThirdApp'
                  : '',attrs:{"to":{
                name: 'School',
                params: { schoolId: school.slug, type: 'overview' }
              }}},[(_vm.lang === 'EN-US')?_c('span',[_vm._v(_vm._s(school.name[_vm.langList[_vm.lang]]))]):_c('span',[_vm._v(_vm._s(school.name[_vm.langList[_vm.lang]])+" ("+_vm._s(school.name["en"])+")")])])],1)])})}}])}),_c('el-table-column',{attrs:{"width":"120","label":_vm.$t('application.columns.actions')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":_vm.getThirdAppDetailRouterObject(scope.row.thirdApp)}},[_c('i',{staticClass:"fas fa-eye action-icon"})]),_c('router-link',{attrs:{"to":_vm.getThirdAppDetailRouterObject(scope.row.thirdApp, {
              isEditLoginInfo: 1
            })}},[_c('i',{staticClass:"fas fa-edit action-icon"})]),_c('span',{staticClass:"warning-icon",on:{"click":function($event){return _vm.removeUsingApplication(scope.row.thirdApp)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.$t('application.chooseApp'),"visible":_vm.setUsingApplicationDialogVisible,"show-close":false,"width":"600px"}},[_c('el-checkbox-group',{on:{"change":value => (_vm.newUsingApplications = value)},model:{value:(_vm.newUsingApplications),callback:function ($$v) {_vm.newUsingApplications=$$v},expression:"newUsingApplications"}},_vm._l((Object.keys(_vm.thirdAppTypeDict)),function(thirdApp){return _c('div',{key:thirdApp},[(_vm.applicationHasColleges(thirdApp))?_c('el-tooltip',{attrs:{"content":_vm.$t('application.removeSchoolBeforeRemovingApp'),"placement":"top","popper-class":"tooltipColor"}},[_c('el-checkbox',{attrs:{"disabled":"","label":thirdApp}},[_vm._v(" "+_vm._s(_vm.thirdAppTypeDict[thirdApp])+" ")])],1):_c('el-checkbox',{attrs:{"label":thirdApp}},[_vm._v(" "+_vm._s(_vm.thirdAppTypeDict[thirdApp])+" ")])],1)}),0),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeSetUsingApplicationDialog}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.updateUsingApplication}},[_vm._v(" "+_vm._s(_vm.$t("button.confirm"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }