<template>
  <span key="isOnline" v-if="isOnline === 1">
    <i class="fa fa-globe" aria-hidden="true" />
  </span>
  <span key="inPerson" v-else-if="isOnline === 0">
    <i class="fa fa-building" aria-hidden="true" />
  </span>
  <span key="inPerson" v-else />
</template>
<script>
export default {
  props: {
    isOnline: {
      type: Number,
      default: null
    }
  }
};
</script>
