<template>
  <div class="tag">
    <el-tag v-if="type === 'DEFAULT'" :size="size" type="info">
      {{ $t("schools.star.Uncategorized") }}
    </el-tag>
    <el-tag v-else-if="type === 'NORMAL'" :size="size" type="success">
      {{ $t("schools.star.Fit school") }}
    </el-tag>
    <el-tag v-else-if="type === 'IDEA'" :size="size" type="warning">
      {{ $t("schools.star.Reach school") }}
    </el-tag>
    <el-tag v-else-if="type === 'DREAM'" :size="size" type="danger">
      {{ $t("schools.star.Dream school") }}
    </el-tag>
    <el-tag v-else-if="type === 'SAFETY'" :size="size" class="safety">
      {{ $t("schools.star.Safety school") }}
    </el-tag>
  </div>
</template>

<script>
export default {
  name: "CollectCollegeTypeTag",
  props: {
    size: {
      type: String,
      default: "default"
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    langIsEn() {
      return this.$i18n.locale === "en";
    }
  }
};
</script>

<style scoped>
.safety {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
}

.tag {
  margin: 0px 4px;
}
</style>